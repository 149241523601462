<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import options from "@/cfg/options.json";


export default {
  data() {
    return {
      options: options,
    };
  },
  components: {fieldsRows},
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              targetNumber: {
                type: 'text',
                name: 'Target Number',
                colAttrs: {style: 'max-width:378px', class: ''},
                fieldAttrInput:{ class:'required',hint:' Example: +49123456789 or Test Variables ','persistent-hint':false } ,
              },
              dialingTime: {
                type: 'text',
                name: 'Dialing Time',
                colAttrs: {cols: 2},
              },
              connectionTime: {
                type: 'text',
                name: 'Connection Time',
                colAttrs: {cols: 2},
              },
              type: {
                type: 'addable',
                associatedOption: this.options.voiceCallType,
                name: 'Type',
                ignoreEmptyAdd: true,
                colAttrs: {cols: 2},
              },
              callId: {
                type: 'select',
                associatedOption: this.options.voiceCallId.filter(item => item[0] !== 'all'),
                name: 'Channel ID',
                ignoreEmptyAdd: true,
                colAttrs: {cols: 2},
              }
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      this.$set(localValue, index, value);
      this.$emit('input', localValue);
     }
  },
};
</script>